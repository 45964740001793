@import url("https://fonts.googleapis.com/css2?family=K2D&display=swap");
body {
  margin: 0;
  font-family: "K2D", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black !important;
  overflow: hidden;
}
input::placeholder {
  color: rgba(194, 188, 188, 0.6) !important;
}
#nest-messages_company_name {
  background-color: #1d1f2a !important;
}
.input-field .ant-input {
  background-color: #1d1f2a !important;
}
textarea::placeholder {
  color: rgba(194, 188, 188, 0.6) !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.main-div {
  background-image: url("./assets/images/Eclipse.svg");
}
html,
body,
.app,
#root {
  height: 100%;
}
.lower-background {
  background-image: url("./assets/images/Group109.svg");
  position: absolute;
  bottom: 0;
  width: 100%;
  /* height: 43%; */
  height: 35%;
  background-repeat: no-repeat;
  /* background-size: 100%; */
  display: none;
}
#root {
  position: relative;
  z-index: 0;
}

.app {
  /* background: radial-gradient(70% 90% at 50% 60%,#6034BF 0,rgba(96,52,191,0.1) 70%); */
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  position: relative;
  z-index: 0;
}

.app::after {
  content: '';
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.65);
  z-index: -1;
}



.ui {
  display: none;
}

canvas {
  position: absolute;
  /* top: 0px; */
  bottom: 20px;
  left: -25%;
  transform: perspective(320px) rotateX(50deg);
  z-index: -1;
}


.ant-drawer-header {
  background-color: black !important;
}
.nav-row {
  padding: 50px 150px;
  justify-content: end;
  display: block;
  text-align: end;
}
.logo-div {
  text-align: center;
  margin-top: 10%;
}
.loader {
  position: absolute !important;
  left: 45% !important;
  top: 50% !important;
  z-index: 99;
}
.loader .ant-spin-dot-item {
  background: linear-gradient(
    180deg,
    #e43659 0%,
    #ff9e1b 41.15%,
    #6034bf 100%
  ) !important;
}
.loader .ant-spin-dot-spin {
  font-size: xx-large;
}
.nav-row .contact-us-button {
  padding: 10px 24px 10px;
  height: 42px;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 5px;
  font-size: 16px;
  background: none;
  line-height: 1;
}
.ant-drawer-close {
  position: absolute;
  right: 40px;
  top: 40px;
}
.ant-drawer-close span {
  color: #fff;
  font-size: 20px;
}
.ant-drawer-body {
  background-color: #1a1616;
}
.main-row {
  margin: 0 35px;
}
.msg-row {
  margin: 20%;
  text-align: center;
}
.msg-row span {
  color: #fff;
}
.msg-row .line-1 {
  font-size: 2.25rem;
  font-weight: 700;
}
.msg-row .line-2 {
  font-size: 20px;
  font-weight: 300;
}
.send-button-row {
  background: linear-gradient(
    180deg,
    #e43659 0%,
    #ff9e1b 41.15%,
    #6034bf 100%
  ) !important;
  border-radius: 5px !important;
  padding: 1px !important;
  text-align: center !important;
  float: right !important;
  margin: 25px 0 !important;
}
.send-button {
  padding: 8px 24px 10px !important;
  width: 160px !important;
  height: 42px !important;
  background: #1d1f2a !important;
  border-radius: 5px !important;
  border: none !important;
}
/* .ant-form-item-has-error {
  background: #2b2d39;
} */
.close-button {
  margin-top: 20px !important;
  background: none !important;
  padding: 10px 24px 10px;
  width: 160px;
}
.logo-line {
  margin: 0;
  margin-top: 35px;
  text-align: center;
  color: #fff;
  line-height: 2;
}
.circle {
  height: 164px;
  width: 164px;
  background-color: rgba(47, 42, 149, 1);
  border-radius: 50%;
  display: inline-block;
}
.row-1 {
  width: 60%;
}
.row-1 .contact {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 300;
  font-size: 14px;
  color: #fff;
}
.row-1 .line1 {
  font-size: 2.25rem;
  font-weight: 700;
  color: #fff;
  line-height: 1.1;
}
.row-2 {
  margin-top: 15px;
  margin-bottom: 15px;
}
.row-2 .line2 {
  font-size: 24px;
  font-weight: 300;
  color: #fff;
}
.contact-form {
  width: 100%;
}
.contact-form .ant-form-item {
  display: block;
  margin-bottom: 0;
}
.ant-form label {
  margin-top: 15px;
  color: rgba(255, 255, 255, 0.6);
}
.contact-form .ant-form-item-label {
  /* margin-bottom: 10px; */
  margin-top: 10px;
}
.contact-form .ant-form-item-label label {
  color: rgba(255, 255, 255, 0.6);
  font-weight: normal;
}
.contact-form .ant-form-item-control-input input {
  height: 46px;
  background: #1d1f2a;
  border: 1px solid #424552;
  border-radius: 5px;
  border-width: 1pt !important;
  color: #fff;
}
.ant-input-group {
  padding: 1px !important;
}

.ant-form-item-control {
  padding: 1px;
}

.input-field .ant-form-item-control:focus,
.input-field .ant-form-item-control:focus-within,
.input-field .ant-form-item-control:active {
  /* height: 48px !important; */
  padding: 1rem !important;
  background: linear-gradient(
    180deg,
    #e43659 0%,
    #ff9e1b 41.15%,
    #6034bf 100%
  ) !important;
  border-radius: 5px !important;
  padding: 1px !important;
}
.phone-input-field .ant-input-wrapper:active,
.phone-input-field .ant-input-wrapper:focus,
.phone-input-field .ant-input-wrapper:focus-within {
  /* height: 55px !important; */
  padding: 1rem !important;
  background: linear-gradient(
    180deg,
    #e43659 0%,
    #ff9e1b 41.15%,
    #6034bf 100%
  ) !important;
  border-radius: 5px !important;
  padding: 1px !important;
}

.phone-input-field .ant-form-item-control-input input:active,
.phone-input-field .ant-form-item-control-input input:focus-within,
.phone-input-field .ant-form-item-control-input input:focus {
  /* height: 48px; */
}
.phone-input-field .ant-input-group-addon {
  background: rgba(29, 31, 42, 1) !important;
  border: 1px solid #424552 !important;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  position: relative;
}

.phone-input-field .ant-input-group-addon:first-child {
  border-right: none !important;
}

.contact-form .ant-form-item-control-input .ant-input-group .ant-input {
  border-left: none;
  box-shadow: none;
}

.phone-input-field .ant-input-group-addon:after {
  content: '';
  position: absolute;
  right: 0px;
  top: 5px;
  height: 34px;
  width: 1px;
  background-color: #424552;

}

.ant-select-dropdown {
  background: rgba(29, 31, 42, 1) !important;
  color: #fff !important;
}
.ant-select-item-option {
  color: #fff !important;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: black !important;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  background-color: black !important;
}
.phone-input-field .ant-select-arrow {
  color: #fff;
}
.phone-input-field .ant-select-selection-item {
  color: #fff !important;
}
.input-field .ant-input-group-wrapper:focus,
.input-field .ant-input-group-wrapper:focus-within,
.input-field .ant-input-group-wrapper:active {
  height: 48px !important;
  padding: 1rem !important;
  background: linear-gradient(
    180deg,
    #e43659 0%,
    #ff9e1b 41.15%,
    #6034bf 100%
  ) !important;
  border-radius: 5px !important;
  padding: 3px !important;
}
.input-field .ant-form-item-control-input input:active,
.input-field .ant-form-item-control-input input:focus-within,
.input-field .ant-form-item-control-input input:focus {
  /* height: 42px; */
}
a {
  color: #fff !important;
}

.text-area-field .ant-form-item-control:focus,
.text-area-field .ant-form-item-control:focus-within,
.text-area-field .ant-form-item-control:active {
  padding: 1rem !important;
  /* height: 158px !important; */
  background: linear-gradient(
    180deg,
    #e43659 0%,
    #ff9e1b 41.15%,
    #6034bf 100%
  ) !important;
  border-radius: 5px !important;
  padding: 1px !important;
}

.contact-form .ant-form-item-control-input textarea {
  height: 123px;
  background: #1d1f2a;
  border: 1px solid #424552;
  border-radius: 5px;
  color: #fff;
}

.send-button-row .ant-form-item-control {
  padding: 0px;
}

.btn-check:focus+.btn-secondary, .btn-secondary:focus {
  box-shadow: none !important;
}

@media screen and (max-width: 1700px) {
  .logo-line {
    font-size: 24px;
    font-weight: 400;
  }
  .center-logo {
    width: 836.43px;
    height: 94.31px;
  }
  .ant-drawer-content-wrapper {
    width: 700px !important;
  }
  .contact-form .ant-form-item-label label {
    font-size: 16px !important;
  }
  .like-img {
    position: absolute;
    top: 105px;
    left: 175px;
  }
}
@media screen and (min-width: 1701px) {
  .logo-line {
    font-size: 32px;
  }
  .center-logo {
    width: 1164px;
    height: 131.24px;
  }
  .ant-drawer-content-wrapper {
    width: 826px !important;
  }
  .contact-form .ant-form-item-label label {
    font-size: 20px !important;
  }
  .like-img {
    /* position: absolute;
    top: 135px;
    left: 225px; */
    margin-bottom: 55px;
    margin-left: -60px;
  }
  #root {
    background-size: 100% !important;
  }
  .lower-background {
    background-size: 100% !important;
  }
}

@media screen and (max-width: 992px) {
  
  #root {
    /* background: radial-gradient(60% 50% at 50% 55%, #6034BF 0%, rgba(96, 52, 191, 0.08) 100%); */
  }

  .center-logo {
    width: 80%;
  }

  .logo-div {
    margin-top: 200px;
  }

  .lower-background {
    background-size: cover;
    background-position: center;
  }
}

@media (max-width: 767px) {
  .logo-line {
    font-size: 16px;
    font-weight: 400;
  }
  .center-logo {
    width: 310px;
    height: 36.08px;
  }
  .ant-drawer-content-wrapper {
    width: 375px;
    height: 920px;
  }
  .contact-form .ant-form-item-label label {
    font-size: 16px !important;
  }
  
  
}

@media screen and (max-width: 570px) {

  .app {
    /* background: radial-gradient(70% 90% at 50% 70%,#6034BF 0,rgba(96,52,191,0.25) 100%); */
  }

  canvas {
    position: absolute;
    /* top: 0px; */
    bottom: 0px;
    left: 0%;
    z-index: -1;
  }

  .logo-div {
    margin-top: 140px;
  }
  .nav-row {
    padding: 30px 32px;
  }
  .logo-line {
    padding: 0px 32px;
  }
  .ant-drawer-content-wrapper {
    width: 100% !important;
  }
  
  .main-row {
    margin: 0px;
  }

  .row-1 {
    width: 80%;
  }

  .row-1 .contact {
    line-height: 18px;
    display: block;
    margin-bottom: 8px;
    margin-top: 18px;
  }
  .row-1 br {
    display: none;
  }

  .ant-drawer-header.ant-drawer-header-close-only {
    justify-content: flex-end;
    padding: 10px 24px;
    background: none;
    /* padding-top: 43px; */
  }
  .ant-drawer-header .ant-drawer-header-title {
    flex: unset;
  }

  .ant-drawer-header-title .ant-drawer-close {
    margin-right: 0px;
  }

  .ant-drawer-close {
    position: unset;
  }

  .ant-drawer-content-wrapper .ant-drawer-body {
     padding-top: 0px;
  }

  .row-1 .line1 {
    font-size: 30px;
  }

  .row-2 {
    margin-top: 16px;
  }
  .row-2 .line2 {
    font-size: 16px;
    line-height: 21px;
  }
  .contact-form .ant-form-item-label label {
    line-height: 150%;
    height: auto;
  }

  .contact-form .ant-form-item-control-input input {
    height: 38px;
  }

  .phone-input-field .ant-input-group-addon:after {
    height: 26px;
  }

  .contact-form .ant-form-item-control-input textarea {
    height: 113px;
  }

  .msg-row {
    margin: 20% 7%;
  }

  .msg-row .line-1 {
    display: inline-block;
    margin-top: 20px;
    font-size: 30px;
    margin-bottom: 25px;
  }

  .msg-row .line-2 {
    font-size: 14px;
  }

  .like-img {
    position: unset;
    margin-left: -30px;
  }

  .msg-row .close-button {
    margin-top: 40px !important;
  }
}

@media screen and (max-width: 370px) {

}